var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"cols":_vm.cols,"request-params":{ with: ['bikes', 'customer.account.parent'] },"filter-keys":_vm.filterKeys,"default-order-by":"created_at","path":"bookings"},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"to":{ name: 'customers.show', params: { customerId: item.customer_id } },"outlined":"","pill":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(item.customer_name)+" ")],1)]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [(item.customer)?[(item.customer.account)?_c('v-chip',{attrs:{"outlined":"","pill":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(item.customer.account.name)+" ")],1):_vm._e(),(item.customer.account)?[(item.customer.account.parent)?_c('v-chip',{attrs:{"outlined":"","pill":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(item.customer.account.parent.name)+" ")],1):_vm._e()]:_vm._e()]:_c('span',[_vm._v("-")])]}},{key:"item.job_numbers",fn:function(ref){
var item = ref.item;
return _vm._l((item.bikes),function(bike){return _c('v-chip',{key:bike.id,staticClass:"my-1 mr-1",attrs:{"to":{ name: 'bookings.show', params: { bookingId: item.id } },"small":""}},[_vm._v(" "+_vm._s(bike.ref || ' - ')+" ")])})}},{key:"item.bikes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.bikes.length))])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$momentDate(value)))])]}},{key:"item.collection_date",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":"orange","small":""}},[_vm._v(_vm._s(_vm.$momentDate(value)))])]}},{key:"item.return_date",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":"green","small":""}},[_vm._v(_vm._s(_vm.$momentDate(value)))])]}},{key:"item.total",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$currency(value))+" ")]}},{key:"item.amount_paid",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$currency(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }