<template>
  <div>
    <DataTable
      :cols="cols"
      :request-params="{ with: ['bikes', 'customer.account.parent'] }"
      :filter-keys="filterKeys"
      default-order-by="created_at"
      path="bookings"
    >
      <template v-slot:item.customer_name="{ item }">
        <v-chip
          :to="{ name: 'customers.show', params: { customerId: item.customer_id } }"
          outlined
          pill
          small
        >
          <v-icon
            small
            left
          >
            mdi-account-circle
          </v-icon>        
          {{ item.customer_name }}
        </v-chip>
      </template>
      <template v-slot:item.account="{ item }">
        <template v-if="item.customer">
          <v-chip
            v-if="item.customer.account"
            outlined
            pill
            small
          >
            <v-icon
              small
              left
            >
              mdi-account-circle
            </v-icon>        
            {{ item.customer.account.name }}
          </v-chip>
          <template v-if="item.customer.account">
            <v-chip
              v-if="item.customer.account.parent"
              outlined
              pill
              small
            >
              <v-icon
                small
                left
              >
                mdi-account-circle
              </v-icon>        
              {{ item.customer.account.parent.name }}
            </v-chip>
          </template>
        </template>
        <span v-else>-</span>
      </template>
      <template v-slot:item.job_numbers="{ item }">
        <v-chip
          v-for="bike in item.bikes"
          :to="{ name: 'bookings.show', params: { bookingId: item.id } }"
          :key="bike.id"
          small
          class="my-1 mr-1"
        >
          {{ bike.ref || ' - ' }}
        </v-chip>
      </template>
      <template v-slot:item.bikes="{ item }">
        <v-chip small>{{ item.bikes.length }}</v-chip>
      </template>
      <template v-slot:item.created_at="{ value }">
        <v-chip small>{{ $momentDate(value) }}</v-chip>
      </template>
      <template v-slot:item.collection_date="{ value }">
        <v-chip color="orange" small>{{ $momentDate(value) }}</v-chip>
      </template>
      <template v-slot:item.return_date="{ value }">
        <v-chip color="green" small>{{ $momentDate(value) }}</v-chip>
      </template>
      <template v-slot:item.total="{ value }">
        {{ $currency(value) }}
      </template>
      <template v-slot:item.amount_paid="{ value }">
        {{ $currency(value) }}
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'

export default {
  name: 'BookingsTable',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Job Numbers', value: 'job_numbers' },
        { text: 'Customer', value: 'customer_name' },
        { text: 'Account', value: 'account' },
        { text: 'Status', value: 'payment_status_label' },
        { text: 'Booked', value: 'created_at' },
        { text: 'Bikes', value: 'bikes' },
        { text: 'Collection Date', value: 'collection_date' },
        { text: 'Return Date', value: 'return_date' },
        { text: 'Booking Total', value: 'total' },
        { text: 'Paid', value: 'amount_paid' }
      ],
      filterKeys: [
        { key: 'id', 'label': 'ID', type: 'String' },
        { key: 'payment_status', 'label': 'Payment Status', type: 'Select', values: [
            { key: 'ABANDONED', label: 'Abandoned' },
            { key: 'AWAITING_PAYMENT', label: 'Awaiting Payment' },
            { key: 'ABANDONED', label: 'Abandoned' },
            { key: 'PAYMENT_FAILED', label: 'Payment Failed' },
            { key: 'AWAITING_FURTHER_PAYMENT', label: 'Awaiting Extra Payment' },
            { key: 'PAYMENT_SUCCEEDED', label: 'Payment Succeeded' },
            { key: 'PAYMENT_REFUNDED', label: 'Payment Refunded' },
            { key: 'PAYMENT_PART_REFUNDED', label: 'Payment Part Refunded' }
        ] }
      ]
    }
  },

  methods: {
    //
  }
}
</script>
