<template>
  <v-container fluid>
    <BookingsTable />
  </v-container>
</template>
<script type="text/javascript">
import BookingsTable from '@/components/BookingsTable'

export default {
  name: 'Bookings',

  components: {
    BookingsTable
  }
}
</script>
